import {
  CHARCOAL_90,
  OwnUpBody,
  OwnUpGridBreak,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpGridWrapper,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';

const DisclaimerWrapper = styled.section`
  background-color: ${CHARCOAL_90};
  padding-top: ${({ theme }) => theme.spacing(4)}px;
  padding-bottom: ${({ theme }) => theme.spacing(4)}px;
  color: white;
`;
const DisclaimerHeader = styled(OwnUpGridItem)`
  font-size: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  margin-bottom: 0;
`;

const DisclaimerBody = styled(OwnUpBody)`
  font-size: 12px;
  line-height: 16px;
`;
const BodyWrapper = styled(OwnUpGridItem)`
  margin-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
`;
const MethodologyLink = styled.a`
  text-decoration: underline;
  color: inherit;
  :visited {
    color: inherit;
  }
`;

const DisclaimerSup = styled.sup`
  padding-right: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(0.5)}px;
`;

export const DisclaimerBlock = () => (
  <DisclaimerWrapper aria-label="Disclaimer">
    <OwnUpGridWrapper>
      <OwnUpGridContainer variant="slim">
        <OwnUpGridOffset xs={0} xl={6} />
        <DisclaimerHeader sm={12} md={12} lg={6}>
          <span>Disclaimer</span>
        </DisclaimerHeader>
        <OwnUpGridBreak />
        <OwnUpGridOffset xs={0} xl={6} />
        <BodyWrapper sm={12} md={12} lg={6}>
          <OwnUpGridBreak />
          <DisclaimerBody variant="body1">
            <DisclaimerSup>1</DisclaimerSup>
            Customers who secure a mortgage from Own Up save an average of $28,170 in interest over
            the life of their loan. Please see how we calculate the number at{' '}
            <MethodologyLink href="/methodology">www.ownup.com/methodology</MethodologyLink>.
          </DisclaimerBody>
        </BodyWrapper>
        <OwnUpGridOffset xs={0} xl={6} />
        <BodyWrapper sm={12} md={12} lg={6}>
          <OwnUpGridBreak />
          <DisclaimerBody variant="body1">
            <DisclaimerSup>2</DisclaimerSup>
            We used $300,000 loan size, $60,000 down payment, 30 year fixed mortgage with 360 total
            number of payments, and interest rates of 6.25% and 6.5% to calculate the monthly
            payment amount.
          </DisclaimerBody>
        </BodyWrapper>
        <OwnUpGridOffset xs={0} xl={6} />
        <BodyWrapper sm={12} md={12} lg={6}>
          <OwnUpGridBreak />
          <DisclaimerBody variant="body1">
            Verified reviews and ratings are powered by Trustpilot, Yotpot, Google, and Zillow.
          </DisclaimerBody>
        </BodyWrapper>
        <OwnUpGridOffset xs={0} xl={6} />
        <BodyWrapper sm={12} md={12} lg={6}>
          <OwnUpGridBreak />
          <DisclaimerBody variant="body1">
            All lender trademarks, service marks, and logos used here are the property of their
            respective owners. They are used here with the appropriate permissions.
          </DisclaimerBody>
        </BodyWrapper>

        <OwnUpGridBreak />
      </OwnUpGridContainer>
    </OwnUpGridWrapper>
  </DisclaimerWrapper>
);
